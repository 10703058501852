import { provideCloudinaryLoader } from '@angular/common';
import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { provideClientHydration, withEventReplay, withHttpTransferCacheOptions, withIncrementalHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withInMemoryScrolling, withViewTransitions } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { routes } from './app.routes';
import { provideZoneChangeDetection } from '@angular/core';

export const appConfig = {
	providers: [
		provideHttpClient(withFetch(), withInterceptorsFromDi()),
		provideZoneChangeDetection({ eventCoalescing: true }),
		provideRouter(
			routes,
			withInMemoryScrolling({
				scrollPositionRestoration: 'enabled',
				anchorScrolling: 'enabled',
			}),
			withViewTransitions({ skipInitialTransition: true }),
		),
		provideClientHydration(
			withHttpTransferCacheOptions({ includePostRequests: true }),
			withEventReplay(),
			withIncrementalHydration(),
		),
		provideAnimations(),
		CookieService,
		provideCloudinaryLoader('https://res.cloudinary.com/gorealtravel'),
	],
};
